




import Vue from "vue";
import {Component, Watch} from "vue-property-decorator";
import {Route} from "vue-router";
import {RouteNext} from "@/types";
import {vxm} from "@/store";
import {needReloadFeatures} from "@/utils/routing";
import {TaxonomyPortrait} from "@/service";

@Component
export default class TaxonomyPortraitsPage extends Vue {


  private created() {
    // Subscribe to mutations in Vue components
    vxm.portraitsModule.$subscribe("onPortraits", payload => {

      if (payload.length == 0)
        return;

      let stillExist = true;

      if (this.$route.name == 'TaxonomyPortrait') {
        const portraits = payload as TaxonomyPortrait[]
        stillExist = portraits.some(p => p.mediaId == +this.$route.params.media)
      }

      const portrait = payload[0];

      if (this.$route.name == 'TaxonomyPortraits' || !stillExist) {
        this.$router.replace({
          name: 'TaxonomyPortrait',
          params: {
            list: this.$route.params.list,
            node: this.$route.params.node,
            media: portrait.mediaId.toString(),
          }
        }).catch(err => err);
      }
    });
  }

  private async beforeRouteEnter(to: Route, from: Route, next: RouteNext) {

    if (needReloadFeatures(to, from)) {
      await vxm.portraitsModule.listNodePortraits({
        listId: +to.params.list,
        nodeId: +to.params.node,
      });
    }
    next();
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: RouteNext) {
    if (needReloadFeatures(to, from)) {
      await vxm.portraitsModule.listNodePortraits({
        listId: +to.params.list,
        nodeId: +to.params.node,
      });
    }
    next();
  }
}
