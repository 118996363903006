import {Route} from "vue-router";

export function isSameMarkingRoute(to: Route, from: Route) {
  return to.name == from.name &&
    to.params.list == from.params.list &&
    to.params.node == from.params.node &&
    to.params.media == from.params.media;
}

export function inMarkingRoute(route: Route) {
  return route.matched.some(r => r.name == 'TaxonomyPortrait');
}

export function needReloadFeatures(to: Route, from: Route): boolean {

  if (to.matched.find(t => t.name == 'TaxonomyPortrait')) {
    if (from.matched.find(t => t.name == 'TaxonomyPortrait')) {
      return to.params.media != from.params.media;
    } else {
      return true;
    }
  } else if (to.matched.find(t => t.name == 'TaxonomyPortraits')) {
    if (from.matched.find(t => t.name == 'TaxonomyPortraits')) {
      return to.params.node != from.params.node;
    } else {
      return true;
    }
  }

  return false;
}
